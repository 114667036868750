const BASE_URL =
  "https://services1.arcgis.com/KGdHCCUjGBpOPPac/arcgis/rest/services/Accessibility_map_layers/FeatureServer/";

export const fetchAgolLayer = ({ layer }: { layer: string }) => {
  return fetch(`${BASE_URL}${layer}/query?where=OBJECTID>0&f=geojson`).then(
    (response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }
  );
};
