import React from "react"
import { Box, makeStyles, Theme, useTheme, useMediaQuery } from "@material-ui/core"
import RoomIcon from '@material-ui/icons/Room';
import TrainIcon from '@material-ui/icons/Train';
import { SvgIconComponent } from "@material-ui/icons";
import { SelectionButton } from "./SelectionButton"

/** Theme used for the button text styling */
const useStyles = makeStyles<Theme>((theme) => ({
    buttonText: {
        fontSize: "12px",
        fontFamily: "Gotham Pro",
        color: theme.palette.grey[400],
        backgroundColor: theme.palette.common.white,
        "& svg": {
            fontSize: 25,
        },
        "&:focus": {
            outline: "none",
        },
    },
}));

export type SelectionType = {
    id: number;
    name: string;
    icon: SvgIconComponent
}

const selections: SelectionType[] = [
    { id: 1, name: "Amenities", icon: RoomIcon },
    { id: 2, name: "Transport", icon: TrainIcon }
]

/**
 * Top level selection menu for the SideDrawer
 */
export const Menu: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme()
    const isXS = useMediaQuery<Theme>(theme => theme.breakpoints.only("xs"))

    return (
        <Box
            className={classes.buttonText}
            display="flex"
            justifyContent="space-between"
            height="100%"
            borderBottom={`1px solid ${theme.palette.grey[200]}`}
            borderTop={isXS && `1px solid ${theme.palette.grey[200]}`}
        >
            {
                selections.map((item: SelectionType) => (
                    <SelectionButton
                        key={item.id}
                        name={item.name}
                        icon={item.icon}
                    />
                ))
            }
        </Box>
    )
}