import { FC } from "react";
import { Drawer, Box, makeStyles, Theme } from "@material-ui/core";
import { Menu } from "./Menu";
import { MobileContentPanel } from "./MobileContentPanel"
import { useUI } from "./UIProvider"


const useStyles = makeStyles<Theme>(() => ({
  mobileMenu: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 1400,
  },
}));

export const BottomNavigation: FC = () => {
  const classes = useStyles();
  const { mobileDrawer, toggleMobileDrawer, setView, layers } = useUI()

  const closeDrawer = () => {
    if (layers.length === 0) {
      setView(undefined)
    }
    toggleMobileDrawer()
  }

  return (
    <>
      <Box className={classes.mobileMenu}>
        <Menu />
      </Box>
      <Drawer variant="persistent" anchor="bottom" open={mobileDrawer} elevation={0}>
        <MobileContentPanel closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
};
