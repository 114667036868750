import React from "react";
import { Box, useTheme } from "@material-ui/core";
import { MapLayerDropDown } from "../map/MapLayerDropDown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type MobileContentPanelProps = {
  closeDrawer: () => void;
};
/**
 * Content of the side panel when a 'view' selection
 * has been made by the user
 */
export const MobileContentPanel: React.FC<MobileContentPanelProps> = ({
  closeDrawer,
}) => {
  const theme = useTheme();

  return (
    <Box bgcolor="white" pb="64px">
      <Box
        width="100%"
        display="flex"
        mt={1}
        justifyContent="center"
        onClick={closeDrawer}
      >
        <ExpandMoreIcon style={{ color: theme.palette.grey[400] }} />
      </Box>
      <Box>
        <MapLayerDropDown />
      </Box>
    </Box>
  );
};
