import { useContext, createContext, FC, useState } from "react";

export type ViewTypes = "amenities" | "transport" | undefined;

export type Layers = string[];

type UIContextType = {
  layers: Layers;
  view: ViewTypes;
  setView: (view: ViewTypes) => void;
  setLayers: (layers: Layers) => void;
  mobileDrawer: boolean;
  toggleMobileDrawer: () => void;
};

const DEFAULT_CONTEXT = {
  view: "amenities" as ViewTypes,
  layers: [],
  setLayers: () => {},
  setView: () => {},
  mobileDrawer: false,
  toggleMobileDrawer: () => {},
};

const UIContext = createContext<UIContextType>(DEFAULT_CONTEXT);

export const UIProvider: FC = ({ children }) => {

  const [layers, dispatchLayers] = useState<Layers>([]);
  const [view, dispatchView] = useState<ViewTypes>(DEFAULT_CONTEXT.view);
  const [mobileDrawer, dispatchMobileDrawer] = useState(true);

  const setLayers = (layers: Layers) => {
    dispatchLayers(layers);
  };

  const setView = (view: ViewTypes) => {
    dispatchView(view);
  };

  const toggleMobileDrawer = () => {
    dispatchMobileDrawer((prev) => !prev);
  };

  return (
    <UIContext.Provider
      value={{
        view,
        layers,
        setLayers,
        setView,
        mobileDrawer,
        toggleMobileDrawer,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => {
  const context = useContext(UIContext);
  if (!context) throw Error("not in <UIContext /> provided");
  return context;
};
