import React, { useEffect } from "react";
import {
  WrappedInteractiveMap,
  useUpdateViewport,
  MELBOURNE_LATITUDE,
  MELBOURNE_LONGITUDE,
} from "@citydna/maps";
import { MapLayers } from "./MapLayers";
import { useMediaQuery, Theme } from "@material-ui/core"

/**
 * Render a mapbox Interactive Map and set the viewport
 * to Melbourne's CBD
 */
export const Map: React.FC = () => {
  const { setViewport, setGlobalBounds } = useUpdateViewport();

  const isXS = useMediaQuery<Theme>(theme => theme.breakpoints.only("xs"))

  useEffect(() => {
    setGlobalBounds([
      [144.9, -37.85],
      [144.99, -37.77],
    ]);
  }, [setGlobalBounds]);

  useEffect(() => {
    if (isXS) {
      setViewport({
        longitude: MELBOURNE_LONGITUDE,
        latitude: MELBOURNE_LATITUDE - 0.005,
        zoom: 13
      })
    } else {
      setViewport({
        longitude: MELBOURNE_LONGITUDE,
        latitude: MELBOURNE_LATITUDE,
        zoom: 15,
      });
    }
  }, [isXS, setViewport]);

  return (
    <WrappedInteractiveMap
      width="100%"
      height="calc(100vh - 48px)"
      mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      dragRotate={false}
      minZoom={isXS ? 11 : 13}
      maxZoom={18}
    >
      <MapLayers />
    </WrappedInteractiveMap>
  );
};
