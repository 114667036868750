import disabledParkingIcon from "../common/assets/disabled_parking.svg"
import publicToiletsIcon from "../common/assets/public_toilets.svg"
import mobilityRechargeIcon from "../common/assets/mobility_recharge.svg"
import seatsIcon from "../common/assets/seats.svg"
import drinkingFountainIcon from "../common/assets/drinking_fountain.svg"
import trainStationEntranceIcon from "../common/assets/train_station_entrance.svg"
import tramStopsIcon from "../common/assets/tram_stops.svg"
import busStopsIcon from "../common/assets/bus_stops.svg"

export type LayerType = {
    id: number;
    title: string;
    slug: string;
    icon: string;
}

export type MapLayerDataType = {
    amenities: LayerType[];
    transport: LayerType[];
}

/**
 * Data used for each of the layers in the application.
 * Split up into subcategories.
 */
export const mapLayerData: MapLayerDataType = {
    amenities: [
        {
            id: 1,
            title: "Disabled parking",
            slug: "disabled-parking",
            icon: disabledParkingIcon
        },
        {
            id: 2,
            title: "Accessible toilets",
            slug: "public-toilets",
            icon: publicToiletsIcon
        },
        {
            id: 3,
            title: "Mobility recharging points",
            slug: "mobility-recharge",
            icon: mobilityRechargeIcon
        },
        {
            id: 4,
            title: "Public seating",
            slug: "seats",
            icon: seatsIcon
        },
        {
            id: 5,
            title: "Drinking fountains",
            slug: "drinking-fountain",
            icon: drinkingFountainIcon
        },
    ],
    transport: [
        {
            id: 1,
            title: "Train station entrances",
            slug: "train-station-entrance",
            icon: trainStationEntranceIcon
        },
        {
            id: 2,
            title: "Tram stops",
            slug: "tram-stops",
            icon: tramStopsIcon
        },
        {
            id: 3,
            title: "Bus stops",
            slug: "bus-stops",
            icon: busStopsIcon
        },
    ]
}