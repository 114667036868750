import React from "react";
import { Box } from "@material-ui/core";
import { MapLayerDropDown } from "../map/MapLayerDropDown";
import { useUI } from "./UIProvider"

/**
 * Content of the side panel when a 'view' selection
 * has been made by the user
 */
export const ContentPanel: React.FC = () => {
  
  const { view } = useUI()

  return view !== undefined ? (
    <Box
      position="relative"
      bgcolor="white"
      maxHeight={500}
      style={{ overflowY: "auto" }}
    >
      <Box p={2}>
        <MapLayerDropDown />
      </Box>
    </Box>
  ) : null;
};
