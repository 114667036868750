import React from "react";
import { Box } from "@material-ui/core";
import { AppHeader } from "@citydna/common";
import { Map } from "../map/Map";

type LayoutProps = {
  isXS: boolean
}

export const Layout: React.FC<LayoutProps> = ({ isXS, children }) => {
  return (
    <>
      {isXS && (
        <Box position="absolute" height="100vh" width="100%">
          <Map />
        </Box>
      )}
      <Box height="100%" display="flex" flexDirection="column" position='relative'>
        <AppHeader title="Access Map" />
        <Box flexGrow={1}>{children}</Box>
      </Box>
    </>
  );
};
