export const layerStyles = {
    "disabled-parking": {
        "layout": {
            "icon-image": "mapicon_parking",
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                14,
                0.4,
                16,
                1
            ]
        }
    },
    "public-toilets": {
        "layout": {
            "icon-image": "mapicon_disabled_toilet",
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                14,
                0.4,
                16,
                1
            ]
        }
    },
    "mobility-recharge": {
        "layout": {
            "icon-image": "mapicon_recharge",
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                14,
                0.6,
                16,
                1
            ]
        }
    },
    "seats": {
        "layout": {
            "icon-image": "mapicon_seat",
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                14,
                0.25,
                16,
                0.75
            ]
        }
    },
    "drinking-fountain": {
        "layout": {
            "icon-image": "mapicon_drink",
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                14,
                0.4,
                16,
                1
            ]
        }
    },
    "train-station-entrance": {
        "layout": {
            "icon-image": "mapicon_train",
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                14,
                0.6,
                16,
                1
            ]
        }
    },
    "tram-stops": {
        "layout": {
            "icon-image": "mapicon_tram",
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                14,
                0.4,
                16,
                1
            ],
        }
    },
    "bus-stops": {
        "layout": {
            "icon-image": "mapicon_bus",
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                14,
                0.4,
                16,
                1
            ]
        }
    }
}