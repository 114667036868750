import React from "react"
import { Box } from "@material-ui/core"

export const SideDrawer: React.FC = ({ children }) => {
    return (
        <Box
            position="absolute"
            display="flex"
            flexDirection="column"
            left={20}
            top={70}
            width={375}
            zIndex={20}
        >
            {children}
        </Box>
    )
}