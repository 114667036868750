import React from "react";
import {
  Box,
  useTheme,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import { useUI, ViewTypes } from "./UIProvider";

const useStyles = makeStyles<Theme>((theme) => ({
  buttonStyle: {
    backgroundColor: theme.palette.common.white,
    borderStyle: "none",
    borderRadius: "none",
    fontFamily: "Gotham Pro"
  },
}));

type SelectionButtonTypes = {
  name: string;
  icon: SvgIconComponent;
};

export const SelectionButton: React.FC<SelectionButtonTypes> = ({
  name,
  icon: Icon,
}) => {
  const theme = useTheme();
  const { setView, view, setLayers, layers, toggleMobileDrawer, mobileDrawer } =
    useUI();

  const classes = useStyles();

  const isXS = useMediaQuery<Theme>((theme) => theme.breakpoints.only("xs"));

  const handleClick = () => {
    if (isXS) {
      // if the active view button is selected
      if (name.toLowerCase() === view) {
        // if there are not layers on the map, unselect the view
        if (layers.length < 1) {
          setView(undefined);
        }
        // close the drawer
        toggleMobileDrawer();
      } else {
        // if the other view is selected or if no previous view selection open the drawer, set the view and clear the layers
        setView(name.toLowerCase() as ViewTypes);
        setLayers([]);
        !mobileDrawer && toggleMobileDrawer();
      }
    } else {
      if (name.toLowerCase() !== view) {
        setView(name.toLowerCase() as ViewTypes);
        setLayers([]);
      }
    }
  };

  return (
    <Box
      component="button"
      className={classes.buttonStyle}
      color={
        view === name?.toLowerCase()
          ? theme.palette.secondary.main
          : theme.palette.grey[400]
      }
      width="100%"
      height={56}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      onClick={handleClick}
    >
      <Icon />
      {name}
    </Box>
  );
};
