import React from "react";
import { Layout } from "./common/Layout";
import { Menu } from "./common/Menu";
import { SideDrawer } from "./common/SideDrawer";
import { ContentPanel } from "./common/ContentPanel";
import { useMediaQuery, Theme } from "@material-ui/core";
import { BottomNavigation } from "./common/BottomNavigation";
import { Map } from "./map/Map"

export const App = () => {
  const isXS = useMediaQuery<Theme>((theme) => theme.breakpoints.only("xs"));

  return (
    <Layout isXS={isXS}>
      {!isXS && <Map />}
      {isXS ? (
        <BottomNavigation />
      ) : (
        <SideDrawer>
          <Menu />
          <ContentPanel />
        </SideDrawer>
      )}
    </Layout>
  );
};
