import React from "react";
import { Source, Layer } from "react-map-gl";
import { layerStyles } from "./layerStyles";
import { useUI } from "../common/UIProvider";
import { useQueries } from "react-query";
import { fetchAgolLayer } from "../common/apiHelpers";

type MapLayerChoices =
  | "disabled-parking"
  | "public-toilets"
  | "mobility-recharge"
  | "seats"
  | "drinking-fountain"
  | "train-station-entrance"
  | "tram-stops"
  | "bus-stops";

export const MapLayers: React.FC = () => {
  const { layers } = useUI();

  const agolLayers = layers.map((layer: string) => getLayer(layer));

  const agolQueries = useQueries(
    agolLayers.map((layer: string) => {
      return {
        queryKey: ["agolLayer", layer],
        queryFn: () => fetchAgolLayer({ layer }),
      };
    })
  );

  return (
    <>
      {agolQueries.map(
        ({ data, isSuccess }, idx: number) =>
          isSuccess && (
            <Source key={`${layers[idx]}-source`} type="geojson" data={data}>
              {/* @ts-ignore */}
              <Layer
                id={`${layers[idx]}-layer`}
                type="symbol"
                beforeId="Label - Park 3"
                {...layerStyles[layers[idx] as MapLayerChoices]}
              />
            </Source>
          )
      )}
    </>
  );
};

const getLayer = (slug: string): string => {
  switch (slug) {
    case "disabled-parking":
      return "disabled_parking";
    case "public-toilets":
      return "public_toilets";
    case "mobility-recharge":
      return "mobility_recharge";
    case "seats":
      return "seats";
    case "drinking-fountain":
      return "drinking_fountain";
    case "train-station-entrance":
      return "train_station_entrance";
    case "tram-stops":
      return "tram_stops";
    case "bus-stops":
      return "bus_stops";
    default:
      return "";
  }
};
