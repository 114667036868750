import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";
import { mapLayerData, LayerType } from "./mapLayerItems";
import { useUI } from "../common/UIProvider";

/**
 * Provides the map layers to the LayerSelectionItem and
 * fetches data to render map layers based on selection.
 * @param view
 */
export const MapLayerDropDown: React.FC = () => {
  const { view, layers, setLayers } = useUI();

  const handleToggle = (layer: string) => {
    if (layers?.includes(layer)) {
      setLayers(layers.filter((x) => x !== layer));
    } else {
        const updatedLayers = [...layers, layer]
        setLayers(updatedLayers)
    }
  };
  
  return (
    <List key={view}>
      {view &&
        mapLayerData[view].map((item: LayerType, idx: number) => (
          <Box key={idx}>
            <ListItem divider>
              <ListItemAvatar>
                <img
                  alt={`${item.title} avatar`}
                  src={item.icon}
                  width={24}
                  height={24}
                />
              </ListItemAvatar>
              <ListItemText id={item.title} primary={item.title} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  size="small"
                  onChange={() => handleToggle(item.slug)}
                  inputProps={{ "aria-labelledby": item.title }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </Box>
        ))}
    </List>
  );
};
