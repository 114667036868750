import React from "react";
import { Theme } from "@citydna/common";
import { MapProvider } from "@citydna/maps";
import { BrowserRouter } from "react-router-dom";
import { UIProvider } from "./UIProvider";
import { QueryClientProvider, QueryClient } from "react-query";

export const Providers: React.FC = ({ children }) => {
  const client = new QueryClient();
  return (
    <Theme>
      <MapProvider>
        <QueryClientProvider client={client}>
          <BrowserRouter>
            <UIProvider>{children}</UIProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </MapProvider>
    </Theme>
  );
};
